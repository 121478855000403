<template>
    <div v-if="loaded" class="fill-height">
        <app-top-menu :key="sidebarKey" :drawer="drawer" @nav-toggle="navToggle"></app-top-menu>
        <v-main>
            <transition name="fade" mode="out-in">
                <router-view :key="componentKey"></router-view>
            </transition>
        </v-main>
        <v-footer bottom fixed padless>
            <app-sidebar-footer></app-sidebar-footer>
        </v-footer>
    </div>
</template>
<script>

import Vue from 'vue'
import mixin from '../../plugins/mixin'

Vue.mixin(mixin)

import Echo from "laravel-echo";
import {mapGetters, mapActions} from "vuex"
import AppSidebarFooter from "../../components/AppSidebarFooter"
import AppTopMenu from "../../components/AppTopMenu"


export default {
    name: "MainLayout",
    components: {
        AppSidebarFooter,
        AppTopMenu
    },
    props: {
        source: String,
        push: Object
    },
    provide: function () {
        return {
            forceRerender: this.forceRerender,
            forceRerenderSidebar: this.forceRerenderSidebar,
            setUserData: this.setUserData,
            DialogProfileSettings: false,
        }
    },
    data() {
        return {
            loaded: false,
            drawer: false,
            componentKey: 0,
            sidebarKey: 0,
            friend: null,
        }
    },
    computed: {
        ...mapGetters(['ulid','type_company_id','company_id','lang', 'language', 'defaultAvatar', 'userAvatar', 'userName', 'userLogin', 'userInitials', 'theme']),
    },
    async mounted() {
        this.appName = process.env.VUE_APP_NAME
        this.drawer = this.$vuetify.breakpoint.mdAndUp
        await this.setTheme()
        await this.$auth
            .load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                    this.setSettings(this.$auth.user().settings)
                    this.setUserName(this.$auth.user().name)
                    this.setUserInitials(this.$auth.user().name)
                    this.setUserPosition(this.$auth.user().position)
                    this.setTypeCompany(this.$auth.user().type_company_id)
                    this.setCompany(this.$auth.user().company_id)
                    this.setUlid(this.$auth.user().ulid)
                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                    this.setCountUnreadNotifications((this.$auth.user().countUnreadNotifications ? this.$auth.user().countUnreadNotifications : this.countUnreadNotifications))
                    this.listenChannelWebsocket()
                }
                this.loaded = true
            })
        this.setLang()
    },
    methods: {
        ...mapActions(['setUlid','setTypeCompany','setCompany','setCountUnreadNotifications','setSettings', 'setLanguage', 'setUserName', 'setUserInitials', 'setUserPosition', 'setUserAvatar']),
        setLang() {
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },
        setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        forceRerender: function () {
            this.componentKey += 1
        },
        forceRerenderSidebar: function () {
            this.sidebarKey += 1
        },
        navToggle() {
            this.drawer = !this.drawer
        },
        listenChannelWebsocket() {
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                disableStats: true,
                encrypted: true,
                enabledTransports: ["wss", "ws"],
                //wsHost: window.location.hostname,
                wsHost: this.$pusherServer,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$http.post(`https://${this.$pusherServer}/api/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data)
                                })
                                .catch(error => {
                                    callback(true, error)
                                })
                        }
                    }
                }
            })

            window.Echo
                .private(`App.Models.Partner.${this.$auth.user().ulid}`)
                .listen('.Event.Partner', (event) => {
                    console.log('MainLayout Event.Partner')
                    if (event.type.indexOf('notify') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    }
                })
            /*
            .notification((notify) => {
                //console.log('MainLayout notify')
                console.log(notify)
                if (notify.type.indexOf('broadcast.message') !== -1) {
                    this.$toastr.success(`${notify.title} ${notify.message}`)
                }
            })
           */
        },
        setUserData() {
            this.setSettings(this.$auth.user().settings)
            this.setUserName(this.$auth.user().name)
            this.setUserInitials(this.$auth.user().name)
            this.setLanguage(this.$auth.user().language)
            this.setUserAvatar(this.$auth.user().avatar)
            this.setTypeCompany(this.$auth.user().type_company_id)
            this.setCompany(this.$auth.user().company_id)
            this.setUlid(this.$auth.user().ulid)
            this.setCountUnreadNotifications(this.$auth.user().countUnreadNotifications)
            this.listenChannelWebsocket()
        },
    }
}
</script>
