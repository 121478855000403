import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: 'main', auth: true},
        component: loadView('Dashboard')
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: {layout: 'main', auth: true},
        component: loadView('Notifications')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: loadView('ProfileForm')
    },
    {
        path: '/qr_payment',
        name: 'qr_payment',
        meta: {layout: 'main', auth: true},
        component: loadView('QrPayment')
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {layout: 'main', auth: true},
        component: loadView('Orders')
    },
    {
        path: '/coffee_house/orders',
        name: 'orders.coffee_house',
        meta: {layout: 'main', auth: {roles: ['coffee_admin','coffee_cashier','coffee_accountant']}},
        component: loadView('CoffeeHouseOrders')
    },
    {
        path: '/restaurant/orders',
        name: 'orders.restaurant',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin','restaurant_cashier','restaurant_accountant']}},
        component: loadView('RestaurantOrders')
    },
    {
        path: '/fitness/orders',
        name: 'orders.fitness',
        meta: {layout: 'main', auth: {roles: ['fitness_admin','fitness_cashier','fitness_accountant']}},
        component: loadView('FitnessOrders')
    },
    {
        path: '/cinema/orders',
        name: 'orders.cinema',
        meta: {layout: 'main', auth: {roles: ['cinema_admin','cinema_cashier','cinema_accountant']}},
        component: loadView('CinemaOrders')
    },
    {
        path: '/shop/orders',
        name: 'orders.shop',
        meta: {layout: 'main', auth: {roles: ['shop_admin','shop_cashier','shop_accountant']}},
        component: loadView('ShopOrders')
    },
    {
        path: '/park/orders',
        name: 'orders.park',
        meta: {layout: 'main', auth: {roles: ['park_admin','park_cashier','park_accountant']}},
        component: loadView('ParkOrders')
    },
    {
        path: '/partner',
        name: 'partner',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('Partners')
    },
    {
        path: '/partner/create',
        name: 'partner.create',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/partner/:id/edit',
        name: 'partner.edit',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/certificate',
        name: 'certificate',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('Certificates')
    },
    {
        path: '/certificate/create',
        name: 'certificate.create',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('CertificateForm')
    },
    {
        path: '/certificate/:id/edit',
        name: 'certificate.edit',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('CertificateForm')
    },

    {
        path: '/news',
        name: 'news',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('News')
    },
    {
        path: '/news/create',
        name: 'news.create',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('NewsForm')
    },
    {
        path: '/news/:id/edit',
        name: 'news.edit',
        meta: {layout: 'main', auth: {roles: ['park_admin','coffee_admin', 'restaurant_admin','fitness_admin','shop_admin','cinema_admin']}},
        component: loadView('NewsForm')
    },


    {
        path: '/tables_coffee_shop',
        name: 'tables_coffee_shop',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('TablesCoffeeShop')
    },
    {
        path: '/tables_coffee_shop/create',
        name: 'tables_coffee_shop.create',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('CoffeeHouseTableForm')
    },
    {
        path: '/tables_coffee_shop/:id/edit',
        name: 'tables_coffee_shop.edit',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('CoffeeHouseTableForm')
    },
    {
        path: '/setting_coffee_house',
        name: 'setting_coffee_house',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('SettingCoffeeHouse')
    },
    {
        path: '/coffee_house_table_reserves',
        name: 'coffee_house_table_reserves',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('CoffeeHouseReservations')
    },
    {
        path: '/coffee_house_menu',
        name: 'coffee_house_menu',
        meta: {layout: 'main', auth: {roles: ['coffee_admin']}},
        component: loadView('CoffeeHouseMenu')
    },


    {
        path: '/tables_restaurant',
        name: 'tables_restaurant',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantTables')
    },
    {
        path: '/tables_restaurant/create',
        name: 'tables_restaurant.create',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantTableForm')
    },
    {
        path: '/tables_restaurant/:id/edit',
        name: 'tables_restaurant.edit',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantTableForm')
    },
    {
        path: '/setting_restaurant',
        name: 'setting_restaurant',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantSetting')
    },
    {
        path: '/restaurant_table_reserves',
        name: 'restaurant_table_reserves',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantReservations')
    },
    {
        path: '/restaurant_menu',
        name: 'restaurant_menu',
        meta: {layout: 'main', auth: {roles: ['restaurant_admin']}},
        component: loadView('RestaurantMenu')
    },

    {
        path: '/type_service',
        name: 'type_service',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('TypeServices')
    },
    {
        path: '/type_service/create',
        name: 'type_service.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('TypeServiceForm')
    },
    {
        path: '/type_service/:id/edit',
        name: 'type_service.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('TypeServiceForm')
    },
    {
        path: '/setting_fitness',
        name: 'setting_fitness',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessSetting')
    },
    {
        path: '/trainer',
        name: 'trainer',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('Trainers')
    },
    {
        path: '/trainer/create',
        name: 'trainer.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('TrainerForm')
    },
    {
        path: '/trainer/:id/edit',
        name: 'trainer.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('TrainerForm')
    },
    {
        path: '/fitness_main',
        name: 'fitness_main',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessMains')
    },
    {
        path: '/fitness_main/create',
        name: 'fitness_main.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessMainForm')
    },
    {
        path: '/fitness_main/:id/edit',
        name: 'fitness_main.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessMainForm')
    },
    {
        path: '/group_lesson',
        name: 'group_lesson',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('GroupLessons')
    },
    {
        path: '/group_lesson/create',
        name: 'group_lesson.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('GroupLessonForm')
    },
    {
        path: '/group_lesson/:id/edit',
        name: 'group_lesson.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('GroupLessonForm')
    },
    {
        path: '/fitness_calendar',
        name: 'fitness_calendar',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessCalendars')
    },
    {
        path: '/fitness_calendar/create',
        name: 'fitness_calendar.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessCalendarForm')
    },
    {
        path: '/fitness_calendar/:id/edit',
        name: 'fitness_calendar.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('FitnessCalendarForm')
    },
    {
        path: '/price_subscription',
        name: 'price_subscription',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('PriceSubscriptions')
    },
    {
        path: '/price_subscription/create',
        name: 'price_subscription.create',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('PriceSubscriptionForm')
    },
    {
        path: '/price_subscription/:id/edit',
        name: 'price_subscription.edit',
        meta: {layout: 'main', auth: {roles: ['fitness_admin']}},
        component: loadView('PriceSubscriptionForm')
    },

    {
        path: '/product',
        name: 'product',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('Products')
    },
    {
        path: '/product/create',
        name: 'product.create',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('ProductForm')
    },
    {
        path: '/product/:id/edit',
        name: 'product.edit',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('ProductForm')
    },

    {
        path: '/category_shop',
        name: 'category_shop',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryShops')
    },
    {
        path: '/category_shop/create',
        name: 'category_shop.create',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryShopForm')
    },
    {
        path: '/category_shop/:id/edit',
        name: 'category_shop.edit',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryShopForm')
    },
    {
        path: '/category_product',
        name: 'category_product',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryProducts')
    },
    {
        path: '/category_product/:category_shop',
        name: 'category_products_filter',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryProducts')
    },
    {
        path: '/category_product/create',
        name: 'category_product.create',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryProductForm')
    },
    {
        path: '/category_product/:id/edit',
        name: 'category_product.edit',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('CategoryProductForm')
    },
    {
        path: '/brand/create',
        name: 'brand.create',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('BrandForm')
    },
    {
        path: '/brand/:id/edit',
        name: 'brand.edit',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('BrandForm')
    },
    {
        path: '/setting_shop',
        name: 'setting_shop',
        meta: {layout: 'main', auth: {roles: ['shop_admin']}},
        component: loadView('ShopSetting')
    },

    {
        path: '/setting_cinema',
        name: 'setting_cinema',
        meta: {layout: 'main', auth: {roles: ['cinema_admin']}},
        component: loadView('CinemaSetting')
    },

    {
        path: '/setting_park',
        name: 'setting_park',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('ParkSetting')
    },

    {
        path: '/attraction',
        name: 'attraction',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('Attractions')
    },
    {
        path: '/attraction/create',
        name: 'attraction.create',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('AttractionForm')
    },
    {
        path: '/attraction/:id/edit',
        name: 'attraction.edit',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('AttractionForm')
    },
    {
        path: '/park_restaurant',
        name: 'park_restaurant',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('ParkRestaurantForm')
    },
    {
        path: '/park_restaurant_menu',
        name: 'park_restaurant_menu',
        meta: {layout: 'main', auth: {roles: ['park_admin']}},
        component: loadView('ParkRestaurantMenu')
    },

    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'single-page', auth: false },
        component: loadView('Auth/Login')
    },

    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },
];

Vue.router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
