<template>
    <v-card color="primary">
        <v-toolbar color="primary" dark>
            <v-btn icon :to="{name: 'notifications'}">
                <v-badge v-if="parseInt(countUnreadNotifications) > 0" color="red white--text" :content="countUnreadNotifications"
                         overlap>
                    <v-icon color="tertiary">mdi-bell-outline mdi-18px</v-icon>
                </v-badge>
                <v-icon v-else color="tertiary">mdi-bell-outline mdi-18px</v-icon>

            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="text-center" style="width:100%">
                <v-img v-if="this.$route.name !== 'dashboard'"
                    class="cursor-pointer"
                    @click="goLink('dashboard')"
                    src="/img/logo.svg"
                    max-height="30px" contain>
                </v-img>
                <v-img v-else
                       src="/img/logo.svg"
                       max-height="30px" contain>
                </v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="DialogProfileSettings = true">
                <v-icon>mdi-account-outline mdi-20px</v-icon>
            </v-btn>
        </v-toolbar>

        <v-dialog
            v-model="DialogProfileSettings"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >

            <v-card>
                <v-toolbar  color="primary">
                    <v-btn icon dark @click="DialogProfileSettings = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title class="text-center white--text" style="width: 100%">{{ $t('personal_account') }}</v-toolbar-title>
                    <v-spacer></v-spacer>

                        <v-btn icon @click="DialogProfileSettings = false"  >

                        </v-btn>

                </v-toolbar>

                <v-list color="my-2">
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-avatar size="40" color="primary"  class="mx-2">
                                <img v-if="userAvatar !== '/img/avatar.png'" :src="userAvatar" :alt="userName">
                                <v-icon v-if="userAvatar === '/img/avatar.png'" dark>
                                    mdi-account
                                </v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ userName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ userPosition }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list color="primary"  >

                    <v-list-item-group>
                        <v-list-item
                            @click="goLink('profile')"
                            href="javascript:void(0)"
                        >
                            <v-list-item-icon>
                                <v-icon class="white--text">mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">{{ $t('profile_editing')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>

                        <v-list-item @click="sheet_language=true">
                            <v-list-item-icon>
                                <v-icon class="white--text">mdi-web</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">{{ $t('language_interface') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="active = active!==true; setTheme(active)">
                            <v-list-item-icon class="mt-5">
                                <v-icon class="white--text">mdi-white-balance-sunny</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="mt-0 pt-0 white--text">{{ $t('switch_to_dark') }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action-text>
                                <v-switch v-model="active" :color="active === false ? 'primary': 'red'"></v-switch>
                            </v-list-item-action-text>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon class="white--text" >mdi-lock-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">{{ $t('privacy_policy') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click.native="logout()">
                            <v-list-item-icon>
                                <v-icon class="white--text">mdi-logout-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">{{$t('logout')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                    </v-list-item-group>

                    <v-bottom-sheet v-model="sheet_language">
                        <v-sheet color="primary">
                            <v-list-item-group>
                                <v-list-item @click="setLang('kk', true)">
                                    <v-list-item-icon>
                                        <v-chip>KZ</v-chip>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{ $t('kazakh')}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="setLang('ru', true)">
                                    <v-list-item-icon >
                                        <v-chip>RU</v-chip>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{ $t('russian')}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="setLang('en', true)">
                                    <v-list-item-icon>
                                        <v-chip>EN</v-chip>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{ $t('english')}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>


                            </v-list-item-group>
                        </v-sheet>

                    </v-bottom-sheet>

                </v-list>
            </v-card>
        </v-dialog>

    </v-card>

</template>
<script>

import {mapGetters, mapActions} from "vuex"


export default {
    name: "AppTopMenu",
    inject: [
        'forceRerender',
        'setUserData',
    ],
    props: {
        drawer: Boolean,
    },
    data() {
        return {
            sheet_language: true,
            active: false,
            DialogProfileSettings:false,
            notifications: [],
            notifyReadCount: 0,
            notifyUnreadCount: 0
        }
    },
    computed: {
        ...mapGetters(['ulid','countUnreadNotifications','lang', 'userPosition', 'userName', 'userAvatar']),
    },
    watch: {
        pushMessages(val) {
            if (val) {
                this.$toastr.success(`${val.title} ${val.message}`)
            }
        },
    },
    mounted() {
        //this.getNotifications()
        this.$auth.load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user()) {
                    this.listenChannelWebsocket()
                }
            })
        this.setLang()
    },
    methods: {
        ...mapActions(['setUlid','setSettings', 'setLanguage', 'setUserName', 'userInitials', 'setUserPosition', 'setUserAvatar']),
        dialogProfileSettings() {
            this.DialogProfileSettings = true
        },
        goLink(data){
            this.DialogProfileSettings = false;
            this.$router.push({
                name: data,

            })
        },
        async getNotifications(action) {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            params.action = action ? action : 'all'
            await this.$http
                .get('admin/notification', {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.notifications = res.body.data

                    this.notifyReadCount = this.notifications.reduce((read, x) => (x.read_at !== null ? (read + 1) : read), 0)
                    this.notifyUnreadCount = this.notifications.reduce((unread, x) => (x.read_at === null ? (unread + 1) : unread), 0)


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_notifications'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
        async setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        async setLang(lang, reload) {
            this.sheet_language = false
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (lang !== undefined) {
                document.documentElement.lang = lang
                this.$i18n.locale = lang
                this.$vuetify.lang.current = lang
            }
            if (typeof this.$i18n.locale !== undefined) {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== undefined) {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            if (reload) {
                this.forceRerender()
            }
        },
        listenChannelWebsocket() {
            if (this.$auth.check() && window.Echo) {
                window.Echo.private(`App.Models.Partner.${this.$auth.user().ulid}`)
                    /*
                    .listen('.Event.Admin', (event) => {
                        //console.log('AppTopMenu Event.Admin')
                        //console.log(event)
                        if (event.type.indexOf('notify') !== -1) {
                            this.$toastr.success(`${event.title} ${event.message}`)
                        }
                    })
                    */
                    .notification((notify) => {
                        //console.log(notify)
                        console.log('AppTopMenu broadcast notify')
                        if (notify.type.indexOf('broadcast.message') !== -1) {
                            this.$toastr.success(`${notify.title} ${notify.message}`)
                        }
                    })
            }
        },
    }
}
</script>
