import { initializeApp } from "firebase/app"
import { getMessaging, isSupported } from "firebase/messaging"
//import { onBackgroundMessage } from 'firebase/messaging/sw'
//import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyAQMPMHgiOKU9YJe21aZDUNborOKdm8xkQ",
    authDomain: "infinitymall-d572e.firebaseapp.com",
    projectId: "infinitymall-d572e",
    storageBucket: "infinitymall-d572e.appspot.com",
    messagingSenderId: "965264128789",
    appId: "1:965264128789:web:d4e46bfc7c2ad17450c71c",
    measurementId: "G-LSBNHTTPL3"
};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
//const analytics = getAnalytics(app)

/*
if (isSupported()) {
    // Register Worker
} else {
    console.log("Browser doesn't support FCM")
}
*/
